.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.photo-gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px; /* Significantly increased gap for larger space between images */
  padding: 20px; /* Padding around the entire gallery for more space from container edges */
}

.photo-gallery img {
  width: 300px; /* Set your desired width */
  height: 350px; /* Set your desired height */
  object-fit: cover; /* This property makes the image cover the area without distortion */
  transition: transform 0.3s ease; /* Smooth transition for scaling */
  cursor: pointer; /* Indicates the image can be clicked */
}

.enlarged {
  transform: scale(3); /* Increased scale for greater enlargement */
  z-index: 10;
  position: relative;
}

/* Optional: Increase image scale on hover for interactive effect */
.photo-gallery img:hover {
  transform: scale(1.05);
}


/* Responsive adjustment for smaller screens */
@media (max-width: 600px) {
  .photo-gallery {
    grid-template-columns: 1fr; /* Adjusts to a single column for small screens */
    gap: 20px; /* Smaller gap for less screen space */
    padding: 10px; /* Adjusted padding for the gallery on smaller screens */
  }
}